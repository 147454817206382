<template>
  <div>
    <component :is="'script'" type="application/ld+json">
      { "@context": "https://schema.org", "@type": "Article",
      "mainEntityOfPage": { "@type": "WebPage", "@id":
      "https://www.lecrandusavoir.fr/" }, "headline": "Le premier manuel
      scolaire numérique et interactif qui s'adapte aux élèves", "description":
      "L'écran du Savoir est une maison d'édition de manuels scolaires
      numériques fondée en 2021", "image":
      "https://www.lecrandusavoir.fr/img/couverture.0c9362c6.png", "author": {
      "@type": "Organization", "name": "L'écran du savoir", "url":
      "https://www.lecrandusavoir.fr/" }, "publisher": { "@type":
      "Organization", "name": "Partenaire du savoir", "logo": { "@type":
      "ImageObject", "url":
      "https://www.lecrandusavoir.fr/img/couverture.0c9362c6.png" } },
      "datePublished": "2023-02-07" }
    </component>
    <v-container class="section-header">
      <header class="header">
        <div class="header-blocs">
          <div>
            <img
              class="borders"
              :src="require('../assets/pictures/image-gauche.jpg')"
              alt="L'écran du savoir - Manuel scolaire numérique"
            />
          </div>
          <div>
            <div class="bloc-logo">
              <div class="logo">
                <img :src="require('../assets/pictures/logo.png')" />
                <h1>L'ÉCRAN DU SAVOIR</h1>
                <h2>
                  Le premier manuel scolaire numérique interactif qui s'adapte
                  aux élèves
                </h2>

                <div class="calendar">
                  <!--<a
                    target="_blank"
                    href="https://docs.google.com/forms/d/e/1FAIpQLScyC8KmJY9QGcPjn771qHPIE27oXkYZXMl6AzCQKDM25hjIxA/viewform"
                    class="btn-link mt-2"
                    >Je m'inscris à un webinaire
                  </a>-->

                  <span
                    @click="scrollMeTo('manuels')"
                    class="btn-link blue-link mt-2"
                  >
                    <strong>Je découvre les spécimens numériques</strong>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="d-none d-sm-flex">
            <img
              class="borders"
              :src="require('../assets/pictures/image-droite.jpg')"
              alt="L'écran du savoir - Manuel scolaire interactif"
            />
          </div>
        </div>
      </header>
    </v-container>
    <v-container fluid class="section-cards">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="cards">
              Un manuel d’histoire-géographie comportant des exercices
              interactifs et différenciés pour une classe participative, qui
              progresse quel que soit le niveau des élèves
            </h2>
            <div>
              <div class="cards">
                <div class="home-card">
                  <div class="card h-card">
                    <img
                      alt="Un meilleur suivi de vos élèves"
                      :src="require('../assets/pictures/picto-4.png')"
                    />
                    <h4>Des questions, des exercices interactifs...</h4>
                  </div>
                  <div class="card h-card">
                    <img
                      alt="Le manuel adapte directement les contenus à vos élèves"
                      :src="require('../assets/pictures/picto-7.png')"
                    />
                    <h4>...différenciés en trois groupes de niveaux</h4>
                  </div>
                  <div class="card h-card">
                    <img
                      alt="Envoyer des exercices du manuel à vos élèves"
                      :src="require('../assets/pictures/picto-6.png')"
                    />
                    <h4>Des contenus vidéos et audios</h4>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <h2 class="cards">
              Le seul manuel interactif qui vous permet de personnaliser les
              exercices et de suivre les résultats de vos élèves
            </h2>
            <div>
              <div class="cards">
                <div class="home-card">
                  <div class="card h-card">
                    <img
                      alt="Des documents et des questions facilement modifiables"
                      :src="require('../assets/pictures/picto-2.png')"
                    />
                    <h4>
                      Modifiez facilement les questions, les textes et les cours
                    </h4>
                  </div>
                  <div class="card h-card">
                    <img
                      alt="Des contenus vidéos facilement disponibles"
                      :src="require('../assets/pictures/picto-3.png')"
                    />
                    <h4>Envoyez vos exercices du manuel à vos élèves</h4>
                  </div>

                  <div class="card h-card">
                    <img
                      alt="Des exercices et questionnaires variés"
                      :src="require('../assets/pictures/picto-1.png')"
                    />
                    <h4>
                      Suivez les résultats de vos élèves grâce au tableau de
                      bord
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container fluid class="section-bubbles">
      <v-container>
        <v-row>
          <v-col cols="8" class="text-left">
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/j6fLHkJBKPg"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </v-col>
          <v-col cols="4" class="text-left">
            <h2 class="cards">
              Un manuel interactif qui s’adapte aux élèves et aux enseignants
            </h2>
            <h3>
              <p>
                Nos manuels s’adaptent aux réponses des élèves, se modifient
                facilement et vous permettent de suivre vos élèves.
              </p>
              <p>Découvrez nos fonctionnalités en quelques minutes.</p>
            </h3>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container fluid class="section-bubbles">
      <v-container>
        <v-row>
          <v-col cols="4" class="text-left">
            <h2 class="cards">
              L’avis des élèves sur notre manuel d’histoire-géographie 2de
            </h2>
            <h3>
              <p>
                Trois élèves ont testé notre manuel, et ont accepté de partager
                avec vous leur expérience.
              </p>
            </h3>
          </v-col>
          <v-col cols="8" class="text-left">
            <div class="video-container">
              <iframe
                src="https://www.youtube.com/embed/5TAc5Nx6ORM"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
    <v-container fluid class="section-bubbles">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="avis text-center mb-8">Avis d'enseignants</h2>
            <div class="bubbles">
              <div class="speech-bubble">
                <cite
                  >Ces versions différentes, c’est hyper intéressant, c’est un
                  des gros défis de l’enseignement, celui de s’adapter aux
                  différents niveaux.</cite
                >
                <em>Etienne, enseignant de la région Paca</em>
              </div>
              <div class="speech-bubble">
                <cite
                  >Ce manuel nous donne des évaluations régulières de nos
                  élèves, nous avons besoin de notes.</cite
                >
                <em>Pierre, enseignant de la région Grand Est </em>
              </div>
              <div class="speech-bubble">
                <cite
                  >On voit qu’ils n’avancent pas tous au même rythme, ça peut
                  éviter que certains décrochent, cette diversité il faut qu’on
                  la prenne en compte.</cite
                >
                <em>Jérôme, enseignant de la région Paca</em>
              </div>
              <div class="speech-bubble">
                <cite
                  >Le fait qu’on ait le retour des exercices dans le tableau de
                  bord, on est sûr qu’ils ont travaillé l’exercice c’est
                  bien.</cite
                >
                <em>Karine, Académie de Nancy-Metz</em>
              </div>
              <div class="speech-bubble">
                <cite>
                  Ces exercices interactifs ça m’évite de créer moi-même mes
                  supports. Ça m’intéresse, je commence toujours par un quiz. Je
                  leur dis &laquo;On va faire ce quiz-là pour mémoriser et être
                  actif&raquo;.</cite
                >
                <em>Mélanie, Académie de Versailles</em>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <v-container fluid class="section-bubbles">
      <v-container>
        <v-row>
          <v-col cols="12">
            <h2 class="avis text-center mb-8">Avis d'élèves</h2>
            <div class="bubbles">
              <div class="speech-bubble">
                <cite
                  >Les exercices apportent une autre approche qui nous aide à
                  mieux retenir les informations et les exemples.</cite
                >
                <em>Enzo, élève de seconde</em>
              </div>
              <div class="speech-bubble">
                <cite
                  >Les cours sont bien construits et faciles à comprendre.
                  »</cite
                >
                <em>Yacine, élève de seconde</em>
              </div>
              <div class="speech-bubble">
                <cite
                  >Cela nous habitue à travailler avec un ordinateur, ce qui est
                  indispensable de nos jours.</cite
                >
                <em>Emma, élève de seconde</em>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-container>

    <section class="le-manuel" ref="manuels">
      <v-container>
        <v-row>
          <v-col cols="12">
            <div class="section-couvertures">
              <div>
                <div>
                  <img
                    alt=""
                    :src="require('../assets/pictures/histo_2.png')"
                  />
                  <p class="credits">
                    Raphael, Jeune femme à la licorne, 1505-1506, Villa and
                    Galleria Borghese, Rome, Lazio, Italy, Bridgeman.
                  </p>
                </div>

                <div class="specs">
                  <h2>Histoire 2<sup>de</sup></h2>
                  <h3>9782958353902</h3>
                  <h4 class="mb-6">
                    8,50&euro; <small><sup>TTC</sup></small>
                  </h4>

                  <div>
                    <a
                      target="_blank"
                      href="https://apis.lecrandusavoir.fr/api/connect_demo_user/histoire2de"
                      class="btn-link w100 mb-16 mb-sm-0"
                    >
                      <strong>Je découvre le manuel</strong>
                      <span>Histoire 2de</span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <img alt="" :src="require('../assets/pictures/geo_2.png')" />
                  <p class="credits">
                    Incendie à la Teste-de-Buch juillet 2022, Abaca Press /
                    Alamy Banque D'Images
                  </p>
                </div>
                <div class="specs">
                  <h2>Géographie 2<sup>de</sup></h2>
                  <h3>9782958353919</h3>
                  <h4 class="mb-6">
                    8,50&euro; <small><sup>TTC</sup></small>
                  </h4>

                  <div>
                    <a
                      target="_blank"
                      href="https://apis.lecrandusavoir.fr/api/connect_demo_user/geographie2de"
                      class="btn-link w100 mb-16 mb-sm-0"
                    >
                      <strong>Je découvre le manuel</strong>
                      <span>Géographie 2de</span>
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <img
                  alt=""
                  :src="require('../assets/pictures/histo_geo_2.png')"
                />
                <div class="specs">
                  <h2>Histoire-Géographie 2<sup>de</sup></h2>
                  <h3>9782958353926</h3>
                  <h4 class="mb-6">
                    12,50&euro; <small><sup>TTC</sup></small>
                  </h4>

                  <div>
                    <a
                      target="_blank"
                      href="https://apis.lecrandusavoir.fr/api/connect_demo_user/histoiregeographie2de"
                      class="btn-link w100 mb-16 mb-sm-0"
                    >
                      <strong>Je découvre le manuel</strong>
                      <span>Histoire/Géographie 2de</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div class="section-couvertures">
              <div>
                <div>
                  <img
                    alt=""
                    :src="require('../assets/pictures/histo_1.png')"
                  />
                  <p class="credits">
                    Le Barbier, Jean-Jacques-François (dit l'Aîné), La
                    déclaration des droits de l'homme et du citoyen, Musée
                    Carnavalet, Histoire de Paris.
                  </p>
                </div>

                <div class="specs">
                  <h2>Histoire 1<sup>ere</sup></h2>
                  <h3>9782958353933</h3>
                  <h4 class="mb-6">
                    8,50&euro; <small><sup>TTC</sup></small>
                  </h4>

                  <a
                    target="_blank"
                    href="https://apis.lecrandusavoir.fr/api/connect_demo_user/histoire1ere"
                    class="btn-link w100 mb-16 mb-sm-0"
                  >
                    <strong>Je découvre le manuel</strong>
                    <span>Histoire 1ere</span>
                  </a>
                </div>
              </div>
              <div>
                <div>
                  <img alt="" :src="require('../assets/pictures/geo_1.png')" />
                  <p class="credits">istock/photology1971</p>
                </div>
                <div class="specs">
                  <h2>Géographie 1<sup>ere</sup></h2>
                  <h3>9782958353940</h3>
                  <h4 class="mb-6">
                    8,50&euro; <small><sup>TTC</sup></small>
                  </h4>
                  <a
                    target="_blank"
                    href="https://apis.lecrandusavoir.fr/api/connect_demo_user/geographie1ere"
                    class="btn-link w100 mb-16 mb-sm-0"
                  >
                    <strong>Je découvre le manuel</strong>
                    <span>Géographie 1ere</span>
                  </a>
                </div>
              </div>
              <div>
                <img
                  alt=""
                  :src="require('../assets/pictures/histo_geo_1.png')"
                />
                <div class="specs">
                  <h2>Histoire-Géographie 1<sup>ere</sup></h2>
                  <h3>9782958353957</h3>
                  <h4 class="mb-6">
                    12,50&euro; <small><sup>TTC</sup></small>
                  </h4>
                  <a
                    target="_blank"
                    href="https://apis.lecrandusavoir.fr/api/connect_demo_user/histoiregeographie1ere"
                    class="btn-link w100 mb-16 mb-sm-0"
                  >
                    <strong>Je découvre le manuel</strong>
                    <span>Histoire/Géographie 1ere</span>
                  </a>
                </div>
              </div>
            </div>

            <div class="offre">
              <p><strong>Offre de découverte</strong></p>
              <p>Une licence offerte pour 30 manuels achetés</p>
            </div>
            <div class="centered-content">
              <h2 class="le-manuel pb-0">
                N'hésitez pas à tester gratuitement nos manuels avec vos élèves
              </h2>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="centered-content subscribe-cta mt-5">
        <div class="btn-link blue-link">
          <a href="https://forms.gle/oC2APUwWRZrziRC66" target="_blank"
            >Inscrivez-vous pour :
            <ul class="text-left">
              <li>recevoir notre spécimen numérique</li>
              <li>
                tester avec vos classes toutes les fonctionnalités du manuel
                d'Histoire-Géographie 2de durant trois mois (échange d’exercices
                et personnalisation)
              </li>
            </ul>
          </a>
        </div>
      </div>
    </section>

    <section class="test-manuel" ref="test-manuel">
      <v-container>
        <v-row>
          <v-col cols="12" sm="4" class="text-center mt-sm-n16">
            <span class="step"> 1 </span>
            <a
              class="d-block white--text"
              href="https://apis.lecrandusavoir.fr/api/connect_demo_user/histoire2de"
              target="_blank"
              >Découvrez notre manuel
              <v-icon color="white" class="ml-1">mdi-open-in-new</v-icon>
            </a>
          </v-col>
          <v-col cols="12" sm="4" class="text-center mt-sm-n16">
            <div class="step">2</div>
            <p>Testez sur une ou plusieurs séances</p>
          </v-col>
          <v-col cols="12" sm="4" class="text-center mt-sm-n16">
            <div class="step">3</div>
            <p>Commandez pour la rentrée prochaine</p>
          </v-col>
        </v-row>
      </v-container>
    </section>

    <v-container class="logos">
      <v-row>
        <v-col cols="12">
          <h2 class="text-center mt-5 mb-2">
            Commandez notre manuel auprès de votre libraire pour une ou
            plusieurs classes !
            <br />
            Avec le numérique chaque enseignant peut enfin choisir son manuel.
          </h2>
          <div class="logos-haut">
            <a target="_blank" href="https://www.arbs.com/">
              <img
                alt="Arbs"
                :src="require('../assets/pictures/Arbs_Logo.png')"
              />
            </a>
            <a
              target="_blank"
              href="https://catalogue.tabuleo.com/?publishers=ECRAN+DU+SAVOIR"
            >
              <img
                alt="Tabuleo"
                :src="require('../assets/pictures/logo-tabuleo.png')"
              />
            </a>

            <a target="_blank" href="https://www.emls.fr/#">
              <img
                alt="Libraire EMLS"
                :src="require('../assets/pictures/logo-emls.png')"
              />
            </a>
            <a target="_blank" href="https://www.lde.fr/">
              <img
                alt="Libraire LDE"
                :src="require('../assets/pictures/logo-lde.png')"
              />
            </a>
            <a target="_blank" href="https://gar.education.fr/">
              <img
                alt="GAR : Gestionnaire d'accès aux ressources numériques"
                :src="require('../assets/pictures/logo-gar.png')"
              />
            </a>
          </div>
        </v-col>
      </v-row>
      <modal-newsletter
        :dialog_newsletter="dialog_newsletter"
        @closeModalNewsletter="closeModalNewsletter"
      ></modal-newsletter>
    </v-container>
  </div>
</template>

<script>
import ModalNewsletter from "../components/ModalNewsletter.vue";

export default {
  title: "Le premier manuel scolaire numérique qui s'adapte aux élèves",
  description:
    "L'écran du savoir propose les premiers manuels numériques interactifs conçu pour les enseignants qui souhaitent différencier facilement leurs cours et mieux évaluer leurs élèves.",
  name: "Home",
  components: {
    ModalNewsletter,
  },
  props: ["email_contact"],
  data: () => ({
    dialog_newsletter: false,
  }),
  methods: {
    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    openModalNewsletter() {
      this.dialog_newsletter = true;
    },
    closeModalNewsletter() {
      this.dialog_newsletter = false;
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$gtag.event("home", { method: "Google" });
  },
};
</script>

<style lang="scss" scoped>
span {
  display: block;
}
.video-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%; /* 16:9 ratio */
  height: 0;
  overflow: hidden;
}
.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}
</style>
